<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                Self Portrait
              </h2>
              <p>Module 1 Assignment</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">
                <div class="col-lg-12">
                  <div class="inner">
                    <div class="section-title">
                      <h2 class="heading-title">Who Am I?</h2>
                      <div
                        class="description mt--30"
                        style="text-align: justify !important"
                      >
                        <v-col md="6" sm="6" cols="6" style="float: left">
                          <div class="thumbnail">
                            <img
                              class="w-100"
                              src="../../assets/images/about/aboutme.jpeg"
                              alt="Cody Squadroni"
                            />
                          </div>
                        </v-col>

                        <p style="text-align: justify !important;">
                          I have never sat down and thought about who I am as a
                          learner or professional, so being approached with this
                          question, I felt the best way to do it was by creating
                          this page.
                        </p>
                        <p>
                          <strong>Who am I as a learner?</strong> I am a person
                          who loves learning. When I want to learn something, I
                          go out and understand it. I have always been a
                          self-taught, self-motivated person because the idea of
                          learning something and being able to apply it or
                          demonstrate it to someone else has always fascinated
                          me. I was a 10-year-old who didn't like to read when I
                          indeed became passionate about learning. At the age of
                          10, new forms of medium were coming out to learn. This
                          happened to be a visual medium that stuck for me, and
                          that is why I thought building a page was ideal
                          because it was what I did when I first started to love
                          the idea of learning truly. I am a visual learner and
                          learning by application. I have learned a lot about
                          everything from this approach of learning. Even though
                          I enjoy reading now, I feel like things stick more
                          when I can apply it to something. I have spent most of
                          my time learning computer science and user experience,
                          but that doesn't mean that is all I enjoy learning. I
                          love learning something about everything! There is so
                          much information out there, and life is so unique. How
                          could you not be interested in learning things about
                          other areas? Whether that be about the ever-growing
                          universe or how to tile a bathroom. If I am approached
                          with an opportunity to learn about something, I will.
                          I learn because I am curious. I have always been
                          curious about how and why things worked the way they
                          did. My parents stopped buying me toys because I would
                          take them apart and put them back together so I could
                          figure out how it worked, sometimes that didn't end
                          well, but I didn't care. I learn because I am curious,
                          and I hope I can use my curiosity to educate others
                          and spark their interest.
                        </p>
                        <p>
                          <strong>Who am I as a professional?</strong> I learned
                          to grow up pretty quickly with not having much growing
                          up. When I graduated high school, I joined the Army. I
                          went to Iraq—opening my eyes to the real world at such
                          a young age made me appreciate life and professional
                          life more. As a professional, I am a person who will
                          push myself to be the best of my ability. In my
                          professional life, I can adapt. I feel like depending
                          on who you are around, and you need to behave a
                          certain way. It's like reading the room but
                          professionally, whether that be with colleagues or
                          students. There is a time to be serious, and there are
                          times to enjoy yourself. I feel like I balance that
                          well when the time calls for it. Currently, in my
                          professional life, I am an instructor at Weber State
                          University. I teach Computer Science and Web and User
                          Experience. I was the program coordinator for the Web
                          and User Experience and created the degree and
                          curriculum from scratch. I did this because there is a
                          need for it, and there were students who didn't enjoy
                          just Computer Science. They wanted the design part as
                          well. As a professional, I like to put the student's
                          needs first if the opportunity is applicable, and in
                          this case, I went full steam ahead. In my field, the
                          learning never stops, and I love that. Technology is
                          constantly changing, so the learning never stops,
                          which is how I became passionate about teaching higher
                          education. Always learning applies to my teaching. I
                          am continually updating the content to give the
                          students the most up-to-date knowledge on topics I
                          cover. I feel like my passion for learning and giving
                          the students the toolset needed for life is what makes
                          me an educator.
                        </p>
                        <p>
                          I fell into teaching without teaching experience but a
                          lot of experience in my topic. I feel like my
                          experience is what has helped me with being an
                          educator. I started this program in LDT because I
                          don't know this area well, and there is always room
                          for improvement. As we start, I have realized that I
                          have unknowingly used some of the theories in my
                          classes already. That makes me even more excited about
                          the program. It shows me how much there is to learn
                          and that I will take so much from this and be able to
                          apply it to my classes. In what way? I'm not sure yet,
                          but I'm already thinking of how to do it. Once I do,
                          it will allow me to make a better impact on students,
                          as that is my main goal. My goal is to keep learning
                          so I can use my experience to inform students. I love
                          learning, but I also love teaching just as much.
                          Getting the information out there in a proactive way
                          is just as important as learning it.
                        </p>
                        <p><strong>I am a lifetime learner!</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
import feather from "feather-icons";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      items: [
        {
          thumb: require("../../assets/images/portfolio/portfolio-big-03.jpg"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      listOne: [
        {
          id: 1,
          icon: "check",
          desc: `5 PPC Campaigns the majority.`,
        },
        {
          id: 2,
          icon: "check",
          desc: `Digital Marketing going to.`,
        },
        {
          id: 3,
          icon: "check",
          desc: ` Marketing Agency passage of.`,
        },
        {
          id: 4,
          icon: "check",
          desc: ` Seo Friendly you are going.`,
        },
      ],
      socialList: [
        {
          url: "https://www.facebook.com/",
          icon: "facebook",
        },
        {
          url: "https://www.linkedin.com/",
          icon: "linkedin",
        },
        {
          url: "https://instagram.com/",
          icon: "instagram",
        },
        {
          url: "https://twitter.com/",
          icon: "twitter",
        },
      ],
      moreProjects: [
        {
          src: require("../../assets/images/portfolio/related-image-01.jpg"),
          title: "Digital Analysis",
          categorie: "Technique",
        },
        {
          src: require("../../assets/images/portfolio/related-image-02.jpg"),
          title: "Plan Management",
          categorie: "Planning",
        },
      ],
      index: null,
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
